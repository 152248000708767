// API routes config
const localhost = false
const origin = window.location.origin
const development = localhost ? 'http://localhost:8000/api' : 'https://cp-pulsawork.sodonbos.com/api'
const production = 'https://cp-pulsawork.sodonbos.com/api'
const API_URL = origin === 'http://localhost:3000' ? development : production

// for loading animation
export const LOADING_TIME = 250

// User Management (Auth)
export const API = {
    API_URL: API_URL,
    LOGIN: API_URL + '/users/login/',
    REFRESH: API_URL + '/users/login/refresh/',
    VERIFY: API_URL + '/users/login/verify/',
    REGISTER: API_URL + '/users/',
    USERS_ME: API_URL + '/users/me/',
    USER_PREFERENCES: API_URL + '/users/me/preferences/',
    PREFERENCES: API_URL + '/companies/preferences/',

    // Leads
    PUBLIC: API_URL + '/public/',
    TRY_LEAD_PUBLIC: API_URL + '/public/lead/',
    LEADS: API_URL + '/sales/leads/',
}
