import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment'
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// import { emailService } from "@store/services/ServiceEmail";
// import { routes } from "../../../store/routes/";

// components
import {
    Button,
    Form,
    Input,
    Message,
    // Select,
    TextArea,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const RequestDemoForm = () => {
    const { t } = useTranslation();

    let language = navigator?.language || "en-us"
    if (language === "en") language = "en-us"

    // state
    const [checked, setChecked] = useState(false);
    // eslint-disable-next-line
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [processing, setProcessing] = useState(false);
    // eslint-disable-next-line
    const [positive, setPositive] = useState(false);
    const [isSended, setIsSended] = useState(false);
    const [errors, setErrors] = useState(null)

    const initFormData = {
        firstname: "",
        lastname: "",
        company: "",
        total_employees: "",
        phone: "",
        email: "",
        source: "",
        message: "",
    };

    const redirectSuccess = () => {
        window.location.href = "https://pulsawork.com/success";
    };

    const redirectError = () => {
        window.location.href = "https://pulsawork.com/error";
    };

    const redirectTERMS = () => {
        window.open("https://pulsawork.com/sk/terms", "_blank");
    };

    const [formData, setFormData] = useState(initFormData);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        setPositive(false);
        setIsSended(false);
        setErrors(null)

        const data = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            company_name: formData.company,
            email: formData.email,
            phone: formData.phone,
            note: formData.message,
            source_text: formData.source,
            number_of_employees_text: formData.total_employees,

            gdpr_is_checked: true,
            marketing_is_checked: true,
            terms_and_conditions_is_checked: true,

            gdpr_date_checked: moment().format("YYYY-MM-DD"),
            marketing_date_checked: moment().format("YYYY-MM-DD"),
            terms_and_conditions_date_checked: moment().format("YYYY-MM-DD"),

            gdpr_reference: "https://pulsawork.com/sk/terms",
            marketing_reference: "https://pulsawork.com/sk/terms",
            terms_and_conditions_reference: "https://pulsawork.com/sk/terms",
            language: language
        }

        const request = await requests.post(API.TRY_LEAD_PUBLIC, data, false);

        if (request.status === 201) {
            // setPositive(true);
            // setIsSended(true);
            // setFormData(initFormData);
            // setChecked(false);
            // setCheckedTerms(false);
            redirectSuccess()
        } else {
            redirectError()
            // setIsSended(true);
            // setErrors(request.response)
        }
        // setProcessing(false);
    };

    return (
        <Form onSubmit={(e) => handleSubmit(e)}>
            <div className="fields-group">
                <Form.Group widths="equal">
                    <Input
                        required
                        placeholder={t("name")}
                        value={formData.firstname}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                firstname: e.target.value,
                            })
                        }
                    />
                    <Input
                        required
                        placeholder={t("surname")}
                        value={formData.lastname}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                lastname: e.target.value,
                            })
                        }
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Input
                        required
                        placeholder={t("phone")}
                        value={formData.phone}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                phone: e.target.value,
                            })
                        }
                    />
                    <Input
                        required
                        type="email"
                        placeholder={t("email")}
                        value={formData.email}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                email: e.target.value,
                            })
                        }
                    />
                </Form.Group>

                <Input
                    style={{ marginBottom: "1rem" }}
                    required
                    placeholder={t("company")}
                    value={formData.company}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            company: e.target.value,
                        })
                    }
                />

                <Form.Group widths="equal">
                    <Form.Field
                        style={{ paddingLeft: 0, paddingRight: "0.2rem" }}
                    >
                        <Form.Select
                            required
                            clearable
                            placeholder={t("employee_count")}
                            options={[
                                { key: 1, value: "1-24", text: "1-24" },
                                { key: 2, value: "25-49", text: "25-49" },
                                { key: 3, value: "50-149", text: "50-149" },
                                { key: 4, value: "150-299", text: "150-299" },
                                { key: 5, value: "300-499", text: "300-499" },
                                { key: 6, value: "500-999", text: "500-999" },
                                { key: 7, value: "1000+", text: "1000+" },
                            ]}
                            value={formData.total_employees}
                            onChange={(e, { value }) =>
                                setFormData({
                                    ...formData,
                                    total_employees: value,
                                })
                            }
                        />
                    </Form.Field>

                    <Form.Field
                        style={{ paddingLeft: "0.2rem", paddingRight: 0 }}
                    >
                        <Form.Select
                            required
                            clearable
                            placeholder={t("how_did_you_find_out_about_us")}
                            options={[
                                { key: 1, value: "Linkedin", text: "Linkedin" },
                                { key: 2, value: "Facebook", text: "Facebook" },
                                { key: 3, value: "Google", text: "Google" },
                                {
                                    key: 4,
                                    value: "Odporúčanie",
                                    text: "Odporúčanie",
                                },
                                { key: 5, value: "Iné", text: "Iné" },
                            ]}
                            value={formData.source}
                            onChange={(e, { value }) =>
                                setFormData({
                                    ...formData,
                                    source: value,
                                })
                            }
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <TextArea
                        className="textarea-box"
                        placeholder={t("message") + " (" + t("optional") + ")"}
                        value={formData.message}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                message: e.target.value,
                            })
                        }
                    />
                </Form.Group>
            </div>

            <Form.Checkbox
                style={{ marginBottom: 0 }}
                checked={checked}
                onChange={() => setChecked(!checked)}
                label={{
                    children: (
                        <p style={{ marginBottom: 0 }}>
                            {t("i_agree_with")}{" "}
                            <Link
                                className="colored"
                                onClick={() => redirectTERMS()}
                            >
                                {t("vop") + " " + t('and') + " " + t("privacy")}
                            </Link>
                            {". "}
                            {t("gdpr_text_rest")}
                        </p>
                    ),
                }}
            />

            <div
                className="full-width-center-content"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Message
                    onDismiss={() => setIsSended(false)}
                    positive={positive && isSended}
                    negative={!positive && isSended}
                    hidden={!isSended}
                    visible={isSended}
                    content={
                        positive && isSended
                            ? t("message_sent_email_notify_text")
                            : !positive && isSended
                            ? errors !== null 
                                ? errors?.text 
                                : t("message_error")
                            : ""
                    }
                />

                <Button
                    primary
                    loading={processing}
                    disabled={
                        formData.firstname === "" ||
                        formData.lastname === "" ||
                        formData.company === "" ||
                        formData.total_employees === "" ||
                        formData.phone === "" ||
                        formData.email === "" ||
                        formData.source === "" ||
                        !checked ||
                        // !checkedTerms ||
                        processing
                    }
                    type="submit"
                    className="button-big"
                >
                    {t("send_request").toUpperCase()}
                </Button>

                <p style={{ textAlign: "center", fontWeight: "bold", marginTop: 0, marginBottom: 0  }}>
                    {t('you_will_recieve_email_with_instruction_for_demo_access')}
                </p>
            </div>
        </Form>
    );
};

export default RequestDemoForm;
