// import { routes } from '@store/routes'
// import Swal from 'sweetalert2'
// import { isEmpty } from './validation'
// import withReactContent from 'sweetalert2-react-content'

// const MySwal = withReactContent(Swal)

// export function alertDialog(
//     title,
//     time,
//     handleClose,
//     handleRestore,
//     endActivityDisplayText,
//     cancelActionDisplayText,
//     countDownTimer
// ) {
//     let dialogCountDownTimer = countDownTimer === undefined ? 3 : countDownTimer
//     let timerInterval
//     MySwal.fire({
//         allowOutsideClick: false,
//         allowEscapeKey: false,
//         title: title,
//         showConfirmButton: false,
//         html:
//             '<p style="font-size: 1.5rem; font-weight: bold;">' +
//             time +
//             '</p>' +
//             endActivityDisplayText +
//             ' <strong></strong>...',
//         timer: dialogCountDownTimer * 1000,
//         timerProgressBar: true,
//         didOpen: () => {
//             MySwal.hideLoading()
//             const b = MySwal.getHtmlContainer().querySelector('strong')
//             timerInterval = setInterval(() => {
//                 b.textContent = Math.ceil(MySwal.getTimerLeft() / 1000)
//             }, 100)

//             document.getElementById('stop').addEventListener('click', async function () {
//                 MySwal.stopTimer()
//                 clearInterval(timerInterval)
//                 await handleRestore()
//                 MySwal.close()
//             })
//         },
//         willClose: () => {
//             clearInterval(timerInterval)
//         },
//         footer: '<h3 id="stop" style="cursor: pointer; text-align: center;">' + cancelActionDisplayText + '</h3>',
//     }).then((result) => {
//         if (result.dismiss === MySwal.DismissReason.timer) {
//             handleClose()
//         }
//     })
// }

// export function generateNumberCombination() {
//     const minLength = 8
//     const maxLength = 10
//     const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength
//     let result = ''

//     for (let i = 0; i < length; i++) {
//         result += Math.floor(Math.random() * 10)
//     }

//     return result
// }

// export function truncateString(str, size) {
//     return str.length > size ? str.substring(0, size - 3) + '...' : str
// }

export const toggleSidebar = () => {
    // find element by classname
    let wrapper = document.querySelector('.wrapper')
    let toggle_icon = document.querySelector('.toggle-icon')

    // check if contain specific classname
    if (wrapper.classList.contains('collapsed')) {
        // remove
        wrapper.classList.remove('collapsed')
        toggle_icon.classList.remove('right')
        toggle_icon.classList.add('left')
    } else {
        // add
        wrapper.classList.add('collapsed')
        toggle_icon.classList.remove('left')
        toggle_icon.classList.add('right')
    }
}

// export function getRandomColor() {
//     var letters = '0123456789ABCDEF'
//     var color = '#'
//     for (var i = 0; i < 6; i++) {
//         color += letters[Math.floor(Math.random() * 16)]
//     }
//     return color
// }

// export function forbidenHandler(request, history) {
//     if (request.status === 403) {
//         history.push(routes.DASHBOARD)
//     }
// }

// export function string_to_slug(str) {
//     str = str.replace(/^\s+|\s+$/g, '') // trim
//     str = str.toLowerCase()

//     // remove accents, swap ñ for n, etc
//     var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
//     var to = 'aaaaeeeeiiiioooouuuunc------'
//     for (var i = 0, l = from.length; i < l; i++) {
//         str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
//     }

//     str = str
//         .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
//         .replace(/\s+/g, '-') // collapse whitespace and replace by -
//         .replace(/-+/g, '-') // collapse dashes

//     return str
// }

export function getValue(object, key) {
    function f(o) {
        if (!o || typeof o !== 'object') {
            return
        }
        if (key in o) {
            reference = o
            return true
        }
        Object.keys(o).some(function (k) {
            return f(o[k])
        })
    }

    let reference
    f(object)
    return reference
}

// export function parseJsonStringToObject(jsonString) {
//     jsonString = jsonString.replace(/'/g, '"')
//     jsonString = jsonString.replace(/None/g, null)
//     jsonString = jsonString.replace(/""/g, null)

//     return JSON.parse(jsonString)
// }

// export function dataURIToBlob(dataURI) {
//     const splitDataURI = dataURI.split(',')
//     const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
//     const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

//     const ia = new Uint8Array(byteString.length)
//     for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

//     return new Blob([ia], { type: mimeString })
// }
// export function makeQuery(property, regexp) {
//     return function (elem, index, array) {
//         return elem[property].search(regexp) !== -1
//     }
// }

// export const getParams = (urlPath) => {
//     if (urlPath === null || urlPath === undefined) return null

//     const url = new URL(urlPath)
//     return url.search
// }

// export const setDotSeparator = (input) => {
//     return input.replace(',', '.')
// }

// export const isValidNumber = (number, allow_blank, allow_negative) => {
//     if (number === '' && allow_blank !== true) return false
//     if (isNaN(number)) return false
//     if (parseFloat(number) < 0 && allow_negative !== true) return false

//     return true
// }

// export const isDescendantElement = (parent, child) => {
//     let node = child.parentNode

//     while (node !== null) {
//         if (node === parent) {
//             return true
//         }
//         node = node.parentNode
//     }

//     return false
// }

// export function getQueryParam(name, url = window.location.href) {
//     // eslint-disable-next-line
//     name = name.replace(/[\[\]]/g, '\\$&')
//     var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//         results = regex.exec(url)
//     if (!results) return null
//     if (!results[2]) return ''
//     return decodeURIComponent(results[2].replace(/\+/g, ' '))
// }

// export function getUrlParameters(url = window.location.href) {
//     let retObject = {},
//         parameters

//     if (url.indexOf('?') === -1) {
//         return null
//     }

//     url = url.split('?')[1]

//     parameters = url.split('&')

//     for (var i = 0; i < parameters.length; i++) {
//         retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1]
//     }

//     return retObject
// }

export function removeURLParameter(url, parameter) {
    var prefix = encodeURIComponent(parameter) + '='
    var pars = url.split(/[&;]/g)

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
        //idiom for string.startsWith
        if (pars[i].startsWith(prefix)) {
            pars.splice(i, 1)
        }
    }

    return pars.join('&')
}

// export function renderShortLongAddress(address) {
//     if (address === null || address === undefined) return '--'

//     if (!isEmpty(address.street) && !isEmpty(address.country)) {
//         let street = address.street

//         if (!isEmpty(address.number) && isEmpty(address.orientation_number)) {
//             street += ` ${address.number}`
//         } else if (isEmpty(address.number) && !isEmpty(address.orientation_number)) {
//             street += ` ${address.orientation_number}`
//         } else if (!isEmpty(address.number) && !isEmpty(address.orientation_number)) {
//             street += ` ${address.orientation_number}/${address.number}`
//         }

//         return (
//             street +
//             ', ' +
//             address.postcode +
//             ' ' +
//             address.city +
//             ', ' +
//             (address?.country_display || address?.country)
//         )
//     } else {
//         return address.city ? address.city + ', ' + (address?.country_display || address?.country) : '--'
//     }
// }

// export function renderAddress(address) {
//     if (address === null || address === undefined) return '--'
//     if (isEmpty(address.street) || isEmpty(address.country)) return '--'

//     let street = address.street

//     if (!isEmpty(address.number) && isEmpty(address.orientation_number)) {
//         street += ` ${address.number}`
//     } else if (isEmpty(address.number) && !isEmpty(address.orientation_number)) {
//         street += ` ${address.orientation_number}`
//     } else if (!isEmpty(address.number) && !isEmpty(address.orientation_number)) {
//         street += ` ${address.orientation_number}/${address.number}`
//     }

//     // Nemcovce 91, 082 12 Nemcovce, Slovensko
//     return street + ', ' + address.postcode + ' ' + address.city + ', ' + (address?.country_display || address?.country)
// }

export function thousandsSeparators(value, separator) {
    if (separator == null) {
        separator = ' '
    }

    // split decimals
    var parts = value.toString().split('.')
    // format whole numbers
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    // put them back together
    value = parts[1] ? parts.join('.') : parts[0]

    return value
}

// export function handleURL(url) {
//     if (url === undefined || url === '' || url === null) return ''

//     url = url.replace('http://', 'https://')

//     // check if there is https:// included
//     if (url.includes('https://')) {
//         return url
//     } else {
//         return `https://${url}`
//     }
// }

// export function capitalizeFirstLetter(str) {
//     return str.charAt(0).toUpperCase() + str.slice(1)
// }
