import React from 'react'
// import { useTranslation } from 'react-i18next'
// store
import '@styles/dashboard.css'
// components
// import Sidebar from './dashboard/Sidebar'
import Sidebar from './dashboard/sidebar/Sidebar'
import DashboardPages from './DashboardPages'
import MainNavigation from '@pages/MainNavigation';

const Dashboard = () => {
    // const { t } = useTranslation()

    return (
        <div className={'wrapper'}>
            <Sidebar />
            <div className="main">
                <MainNavigation/>
                <DashboardPages />
            </div>
        </div>
    );
};

export default Dashboard;