import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// store
import { routes } from '@routes';
import { authService } from '@services/ServiceAuth';
// components
import Loading from '../general/Loading';

const PrivateRoute = ({component: Component, modules}) => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPath] = useState(window.location.pathname);

    useEffect( () => {
        async function checkUser() {
            const result = await authService.validateToken();
            setIsLoading(false);

            if( result === false ){
                history(routes.LOGIN);
            }
        };

        checkUser();
    }, [history, currentPath]);


    return (
        <>
            { !isLoading ? <Component/>
            : <Loading />}
        </>
    );
};

export default PrivateRoute;