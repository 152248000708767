import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import {routes} from '@routes'
import { toggleSidebar } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import { List } from 'semantic-ui-react'
import SidebarItem from './SidebarItem'

const Sidebar = () => {
    const { t } = useTranslation()

    // eslint-disable-next-line
    const [toggled, setToggled] = useState(false)

    function Toggle() {
        toggleSidebar()
    }

    function ToggleWithClick() {
        if (window.innerWidth <= 440) {
            Toggle()
        }
    }

    return (
        <>
            <div className={toggled ? 'sidebar scrollable' : 'sidebar'}>
                <List.Content>
                    <List className="siderbar-list" style={{ marginBottom: 0 }}>
                        <div onClick={Toggle} style={{ cursor: 'pointer', padding: '0.5rem', marginTop: '1rem' }}>
                            <span className="hide-text" style={{ position: 'relative', top: '-0.2rem' }}>
                                {t('hide_sidebar')}
                            </span>
                            <Icon
                                name="chevron-back-outline"
                                style={{ margin: 0, marginLeft: '1rem' }}
                                className="toggle-icon"
                            />
                        </div>
                    </List>
                    
                       
                    <List className="siderbar-list">
                        <SidebarItem
                            permissions={['']}
                            modules={[]}
                            to={routes.LEADS}
                            text={t('leads')}
                            icon={'people-outline'}
                            hide={toggled}
                            toggle={() => ToggleWithClick()}
                        />
                        <SidebarItem
                            permissions={['']}
                            modules={[]}
                            to={routes.ORDERS}
                            text={t('orders')}
                            icon={'documents-outline'}
                            hide={toggled}
                            toggle={() => ToggleWithClick()}
                        />
                    </List>
                </List.Content>
            </div>
        </>
    )
}

export default Sidebar
