import React from 'react';
import { Container } from 'semantic-ui-react';

const DashboardView = () => {
    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            Dashboard in preparation...
        </Container>
    );
};

export default DashboardView;