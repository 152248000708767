import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';



const SuperDuperModal = ({ trigger, content, size, header, closeIcon, ...rest }) => {
    // trigger = {node}
    // content = {elementType}

    // **** optional
    // size = {enum} mini, tiny, small, large, fullscreen... TINY by default
    // header = {string} OPTIONAL

    const [open, setOpen] = useState(rest?.open || false)
    const [customSize, setCustomSize] = useState(size || "tiny")
    const [customHeader, setCustomHeader] = useState(header || undefined)

    let closeIconProp = true
    if( closeIcon === false ){
        closeIconProp = false
    }

    return (
        <Modal
            {...rest}
            onClose={() => { 
                rest?.onClose && rest.onClose()
                setOpen(false)
                setCustomSize(size || "tiny")
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
            closeIcon={closeIconProp}
            size={customSize}
        >
            {customHeader && <Modal.Header>{customHeader}</Modal.Header>}
            <Modal.Content>
                { 
                React.cloneElement(content, { 
                    onClose: () => { 
                        setOpen(false)
                        setCustomSize(size || "tiny") 
                        setCustomHeader(header || undefined) 
                    },
                    setSize: (cSize) => setCustomSize(cSize),
                    setHeader: (cHeader) => setCustomHeader(cHeader)
                })}
            </Modal.Content>

        </Modal>
    )
};

export default SuperDuperModal;