import moment from 'moment';
// store
import { store } from "@store";

function getTimezoneFromRedux() {
    const state = store.getState();
    return state?.timezone || 0
}

store.subscribe(getTimezoneFromRedux)

export function getTimezoneOffset() {
    let offset = 0
    const currentOffsetInHours = new Date().getTimezoneOffset() / 60

    if (currentOffsetInHours > 0) offset = currentOffsetInHours * -1 
    if (currentOffsetInHours < 0) offset = currentOffsetInHours * -1 

    return offset
}

function GetFormattedDate(date) {
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var year = date.getFullYear();
    var hour = ('0' + date.getHours()).slice(-2);
    var min = ('0' + date.getMinutes()).slice(-2);
    var seg = ('0' + date.getSeconds()).slice(-2);
    return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seg;
}

function convertUTCDateToLocalDate(date) {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return GetFormattedDate(newDate);
}

export function tzDateTime(datetime, backToUTC) {
    let offset = getTimezoneFromRedux()
    let defaultFormat = "YYYY-MM-DD HH:mm:ss"
    // eslint-disable-next-line
    let convertOffsetToFloat = parseFloat(offset) || 0
    let momentDatetime = moment(datetime, defaultFormat)

    // if (convertOffsetToFloat === 0) { // UTC+0 = default attempt to convert to browser timezone based on device location
    if (backToUTC) {
        momentDatetime = momentDatetime.add(new Date().getTimezoneOffset() / 60, "hours")
    } else {
        momentDatetime = moment(convertUTCDateToLocalDate(moment(momentDatetime).format(defaultFormat)))
    }
    
    return momentDatetime
}