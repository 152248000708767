const dashboard = '/dashboard/'; // this is main prefix for dashboard pages

export const routes = {
    // Public
    HOME: '/',

    // Auth/Users
    LOGIN: '/login/',

    // Dashboard
    DASHBOARD: dashboard,
    LEADS: "leads/",
    ORDERS: "orders/",
};