import React from 'react';
import { Routes, Route } from 'react-router-dom';
// store
import { routes } from '@routes'
// components
import Leads from './dashboard/leads'
import Orders from './dashboard/orders'
import DashboardView from './dashboard/'

const DashboardPages = () => {
    return (
        <Routes>
            <Route path={''} element={<DashboardView/>} />
            <Route path={routes.LEADS} element={<Leads/>} />
            <Route path={routes.ORDERS} element={<Orders/>} />
        </Routes>
    );
};

export default DashboardPages;