import React, { useState, useEffect  } from "react";
// import { Sidebar, Segment/*, Responsive*/ } from "semantic-ui-react";
// import { useGesture } from "react-use-gesture";

const SidebarPanel = ({ uniqueKey, trigger, content, width, ...rest}) => {
    const [open, setOpen] = useState(rest?.open || false)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        document.addEventListener("keydown", handleKeyClose, false);
        
        return () => {
            document.removeEventListener("click", handleClickOutside, false)
            document.removeEventListener("keydown", handleKeyClose, false);
        }

        // eslint-disable-next-line
    }, [])

    const handleKeyClose = event => {
        if (event.keyCode === 27) {
            setOpen(false)
        }
    }
    
    const handleClickOutside = event => {
        const overlays = document.getElementsByClassName('side-panel-overlay')
        if (overlays.length > 0) {
            for (let i = 0; i < overlays.length; i++) {
                if (overlays?.[i] === event.target) {
                    setOpen(false)
                    return
                }
            }
        }
    }

    // const bind = useGesture({
    //     onDrag: ({ delta: [xDelta], down, distance, cancel }) => {
    //     if (down && distance > 30 && isMobile) {
    //         setOpen(xDelta > 0);
    //         cancel();
    //     }
    //     }
    // });

    return (
        <>
        { 
            React.cloneElement(trigger, { 
                onClick: () => setOpen(true),
                className: uniqueKey !== undefined ? `side-panel-toggle_${uniqueKey}` : `side-panel-toggle`
            })
        }
        <div className={`side-panel-overlay ${open && "show"}`}></div>
        <div 
            className={`side-panel ${open && "open"}`}
            style={{ width: width || "850px", padding: 0 }}
        >
            <div className="side-panel-content">
                {open && content}
            </div>
        </div>
        </>
    )
}

export default SidebarPanel