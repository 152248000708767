import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './styles.css'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Loading from '@components/general/Loading'
import { Container, Image, Header, Grid, Form, Button } from 'semantic-ui-react';
import SuperField from 'components/forms/SuperField';

const Unsubscribe = () => {
    let { uuid } = useParams();

    const { t, i18n } = useTranslation()

    const handleRedirect = () => {
        window.location.href = "https://pulsawork.com";
    };

    const [loading, setLoading] = useState(true)
    const [unsubscribe, setUnsubscribe] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [reason, setReason] = useState("")

    const [lead, setLead] = useState({})

    useEffect(() => {
        let language = navigator?.language || "en"
        if (language === "en-us") language = "en" 
        i18n.changeLanguage(language)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchLeadData(){
            setLoading(true)
            const request = await requests.get(API.PUBLIC + "lead/" + uuid + "/unsubscribe/", false) 
            if (request.status === 200) setLead(request.response)
            setLoading(false)
        }

        fetchLeadData()
    }, [uuid])

    const handleSubmit = async () => {
        setProcessing(true)

        const request = await requests.patch(API.PUBLIC + "lead/" + uuid + "/unsubscribe/", {
            unsubscribed: true, unsubscribe_reason: reason
        }, false)
        if (request.status === 200) {
            setLead(prev => ({...prev, unsubscribed: true}))
        }

        setProcessing(false)
    }

    return (loading ? <Loading/> :
            <Container>
                <Image
                    onClick={() => handleRedirect()}
                    style={{ cursor: "pointer", maxWidth: "140px", marginTop: "1rem" }}
                    src="/images/PULSAWORK.svg"
                    alt="logo"
                    width="25%"
                    centered
                />

                <Grid style={{ marginTop: "3rem" }}>
                    <Grid.Row centered>
                        <Grid.Column mobile={16} tablet={3} computer={4} className="d-sm-none">
                            <Image src="/images/login_1.svg" />
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={10} computer={8}>
                            <div className="box light form mx-auto">
                                { lead.unsubscribed === false 
                                    ? 
                                        <Form onSubmit={handleSubmit}>
                                            <div className="title-section" style={{ marginTop: "0rem", textAlign: "left", marginBottom: "1rem" }}>
                                                <Header className="large" as="h2" content={t('unsubscribe')}/>
                                            </div>
                                            
                                            {t('email')}: <strong>{ lead.email }</strong> <br/><br/>

                                            <Form.Field style={{ fontWeight: 'bold' }}>{t('campaign_settings')}:</Form.Field>
                                            <SuperField required as="checkbox" label={t('unsubscribe_from_the_email_campaign')} checked={unsubscribe} onChange={() => setUnsubscribe(!unsubscribe)}/>

                                            <SuperField as="textarea" label={t('please_tell_us_why_you_want_to_unsubscribe')} value={reason} onChange={(e, { value }) => setReason(value)}/>

                                            <Button
                                                primary
                                                loading={processing}
                                                type="submit"
                                                className="button-big"
                                                disabled={unsubscribe === false}
                                            >
                                                {t("unsubscribe").toUpperCase()}
                                            </Button>
                                        </Form>
                                    : 
                                        <div className="title-section">
                                            <Header className="large" as="h2" content={t('already_unsubscribed')}/>
                                        </div>
                                }
                            </div>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={3} computer={4}>
                            <Image src="/images/login_2.svg" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
        </Container>
    );
};

export default Unsubscribe;