import React from 'react';
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
// components
import BreadcrumbNav from '@components/BreadcrumbNav'
import ListView from '@components/ListView'
import { Container } from 'semantic-ui-react';

const Leads = () => {
    const { t } = useTranslation()
    return (
        <>
            <BreadcrumbNav items={[{ name: t('leads'), icon: '', href: '' }]} mBottom="0rem" />
            <Container fluid>
                <ListView
                    as="table"
                    allowSearch
                    allowSelection
                    endpoint={API.LEADS}
                    actionsCellWidth="1"
                    tableHeaders={[
                        { title: t('name') },
                        { title: t('surname') },
                        { title: t('company') },
                        { title: t('phone') },
                        { title: t('email') },
                        { title: t('employee_count') },
                        { title: t('source') },
                        { title: t('message') },
                        { title: t('created_on') },
                    ]}
                    actions={[
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('delete_lead_msg'),
                        },
                    ]}
                    bulkActions={(selected) => [
                        {
                            as: 'custom',
                            name: "Action 1",
                            onClick: () => console.log("Action 1"),
                        },
                        {
                            as: 'custom',
                            name: "Action 2",
                            onClick: () => console.log("Action 2"),
                        },
                    ]}
                    initialFilters={{
                        // query: '',
                        ordering: ['-created_on']
                    }}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            Well... there are no filters yet.
                        </>
                    )}
                    renderCells={(lead) => [
                        { content: lead.firstname },
                        { content: lead.lastname },
                        { content: lead.company_name },
                        { content: lead.phone },
                        { content: lead.email },
                        { content: lead.number_of_employees_text },
                        { content: lead.source_text },
                        { content: lead.note },
                        { content: tzDateTime(lead.created_on).format("DD.MM.YYYY HH:mm:ss") },
                    ]}
                />
            </Container>
        </>
    );
};

export default Leads;