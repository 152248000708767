import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '@routes';
import { useHasPermissions, useHasEveryPermissions } from '@helpers/hooks';
// components
import CanAccess from '@components/perms/CanAccess';

const CanView = ({ permissions, modules, children, redirect, route, matchEveryPermission, render, ...rest }) => {

    permissions = permissions || [];

    const hasEveryPerms = useHasEveryPermissions(permissions)
    const hasSomePerms = useHasPermissions(permissions)
    let canRender = matchEveryPermission ? hasEveryPerms : hasSomePerms
    if (permissions === undefined || permissions.length === 0) {
        canRender = true
    } else {
        canRender = render || canRender
    }

    return (
        <CanAccess allowedModules={modules}>
            {canRender ? children : redirect && <Navigate to={route || routes.DASHBOARD} replace/>}
        </CanAccess>
    )
}

export default CanView;