import { combineReducers } from "redux";
// components
import userReducer from "./user";
import choicesReducer from "./choices";

const allReducers = combineReducers({
    user: userReducer,
    choices: choicesReducer
});

export default allReducers;