import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import 'semantic-ui-css/semantic.min.css';
import '@styles/default.css'
import '@styles/themes.css'
// store
import './store/i18n'
import PublicRoute from '@components/routes/PublicRoute';
import PrivateRoute from '@components/routes/PrivateRoute';
import withClearCache from '@store/connector/withClearCache';
import { withRouter } from '@store/helpers/withRouter';
// components
import Home from '@pages/public/Home';
import Login from '@pages/public/Login';
import TryPage from '@pages/public/trypage';
import Unsubscribe from '@pages/public/unsubscribepage/Unsubscribe';
import Dashboard from '@pages/Dashboard';

function App() {
  const language = useSelector((state) => state.language)
  
  // eslint-disable-next-line
  const [mode, setMode] = useState('light')
  const [initLang] = useState(language || 'en')
  const { i18n } = useTranslation()

  useEffect(() => {
      i18n.changeLanguage(initLang)
  }, [i18n, initLang])

  useEffect(() => {
    // 👇 add class to body element
    document.body.classList = ''
    document.body.classList.add(mode)
}, [mode])

  return (
      <Routes>
        <Route exact path="/" element={<PublicRoute component={Home} restricted={false}/>} />
        <Route exact path="/try/" element={<PublicRoute component={TryPage} restricted={false}/>} />
        <Route exact path="/email/unsubscribe/:uuid/" element={<PublicRoute component={Unsubscribe} restricted={false}/>} />
        <Route exact path="/login/" element={<PublicRoute component={Login} restricted={true}/>} />
        <Route exact path="/dashboard/*" element={<PrivateRoute component={Dashboard} restricted={true}/>} />
      </Routes>
  );
}

export default withRouter(withClearCache(App))