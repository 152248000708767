import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
// store
import { routes } from '@routes';
import { authService } from '@services/ServiceAuth';
// components
import { Container, Form, Grid, Segment, Image } from 'semantic-ui-react'

const Login = () => {
    const dispatch = useDispatch();
    let history = useNavigate();
    const { i18n, t } = useTranslation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage] = useState(t('login_error'));

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        setErrorOpen(false);

        // validate form fields
        let formError = false;

        if (!formError) {
            const result = await authService.login(
                username,
                password,
                dispatch,
                i18n
            );

            if (result.status !== 200) {
                setErrorOpen(true);
            } else {
                history(routes.DASHBOARD);
                return;
            }
        }
        setIsProcessing(false);
    }

    useEffect(() => {
        setErrorOpen(false)
    }, [username, password])

    useEffect(() => {
        let language = navigator?.language || "en"
        if (language === "en-us") language = "en" 
        i18n.changeLanguage(language)
        // eslint-disable-next-line
    }, [])

    return(
        <div className="center-element">
            <Image src="/images/login_1.svg" style={{ position: 'absolute', width: '300px', marginLeft: '25rem' }} />
            <Container style={{ zIndex: '2' }}>
                <Grid centered>
                    <Grid.Column style={{ maxWidth: 450, padding: 0 }}>
                        <Form onSubmit={(e) => handleLogin(e)}>
                            <Segment style={{ borderRadius: 'var(--border-radius)' }}>
                                <Image centered size="small" src={"https://hriis.s3.amazonaws.com/pages-hriis/media/uploads/pictures/PULSAWORK_logo.svg"} />
                                <h1 style={{ textAlign: "center" }}>{t("login")}</h1>
                                <Form.Group>
                                    <Form.Input
                                        icon="user"
                                        iconPosition="left"
                                        width={16}
                                        size="large"
                                        required
                                        autoFocus
                                        fluid
                                        label={t("username")}
                                        placeholder={t("username")}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        error={errorOpen ? errorMessage : false}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Input
                                        icon="lock"
                                        iconPosition="left"
                                        width={16}
                                        size="large"
                                        required
                                        fluid
                                        type="password"
                                        label={t("password")}
                                        placeholder={t("password")}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        error={errorOpen ? errorMessage : false}
                                    />
                                </Form.Group>

                                <div style={{ width: "100%" }}>
                                    <Form.Button
                                        fluid primary
                                        size="huge"
                                        type="submit"
                                        loading={isProcessing}
                                        disabled={
                                            isProcessing ||
                                            username === "" ||
                                            password === ""
                                        }
                                        style={{ margin: "0.5rem auto" }}
                                    >
                                        {t('login')}
                                    </Form.Button>
                                </div>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Container>
            <Image
                src="/images/login_2.svg"
                style={{ position: 'absolute', width: '300px', right: 0, marginRight: '25rem' }}
            />
        </div>
    );
}

export default Login;