import React from 'react';
import { useTranslation } from 'react-i18next'
// components
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container } from 'semantic-ui-react';

const Orders = () => {
    const { t } = useTranslation()
    return (
        <>
            <BreadcrumbNav items={[{ name: t('orders'), icon: '', href: '' }]} mBottom="0rem" />
            <Container fluid>
                Orders in preparation...
            </Container>
        </>
    );
};

export default Orders;