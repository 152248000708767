import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '@routes';
import { useHasModuleAllowed } from '@helpers/hooks';

const CanAccess = ({allowedModules, children, redirect, route, render, ...rest}) => {
    allowedModules = allowedModules || [];

    let canRender = useHasModuleAllowed(allowedModules);
    if( allowedModules === undefined || allowedModules.length === 0 ){
        canRender = true
    } else {
        canRender = render ? render : canRender 
    }

    return (
        <>
        {canRender ? children : redirect && <Navigate to={route||routes.DASHBOARD} replace/>}
        </>
    )
}

export default CanAccess;