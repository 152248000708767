import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './trypage.css'
// components
import { Container, Grid, Header, Image } from 'semantic-ui-react'
import RequestDemoForm from './RequestDemoForm'

const TryPage = () => {
    const { t, i18n } = useTranslation()

    const handleRedirect = () => {
        window.location.href = "https://pulsawork.com";
    };

    useEffect(() => {
        let language = navigator?.language || "en"
        if (language === "en-us") language = "en" 
        i18n.changeLanguage(language)
        // eslint-disable-next-line
    }, [])

    return (
        <Container>
            <Image
                onClick={() => handleRedirect()}
                style={{ cursor: "pointer", maxWidth: "140px", marginTop: "1rem" }}
                src="/images/PULSAWORK.svg"
                alt="logo"
                width="25%"
                centered
            />

            <div className="title-section" style={{ marginTop: "3rem" }}>
                <Header className="large" style={{ fontSize: "2rem" }}>{t('try_header')}</Header>
                <p style={{ fontWeight: "bold" }}>{t('try_description')}</p>
            </div>


            <Grid>
                <Grid.Row centered>
                    <Grid.Column mobile={16} tablet={3} computer={4} className="d-sm-none">
                        <Image src="/images/login_1.svg" />
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={10} computer={8}>
                        <div className="box light form mx-auto">
                            <RequestDemoForm />
                        </div>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={3} computer={4}>
                        <Image src="/images/login_2.svg" />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default TryPage;