import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
// store
import { routes } from '@routes/index'
import { authService } from '@services/ServiceAuth'
// eslint-disable-next-line

import {
    Menu,
    Image,
    Dropdown,
    // Icon,
    // Search,
    Label,
} from 'semantic-ui-react'
import Icon from '@components/Icon'

function MainNavigation({ isFullWidth }) {
    const { t } = useTranslation()
    const history = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    const logout = () => {
        authService.logout(dispatch)
        history(routes.LOGIN)
    }

    return (
        <Fragment>
            <div className="bigMenu">
                <Menu
                    stackable
                    fixed="top"
                    borderless
                    style={isFullWidth ? { left: 0, width: '100%', height: '65px' } : { height: '65px' }}
                >
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <Link to={routes.DASHBOARD}>
                                <Image size="tiny" src={"https://hriis.s3.amazonaws.com/pages-hriis/media/uploads/pictures/PULSAWORK_logo.svg"} style={{ maxHeight: '43px', height: '100%' }} />
                            </Link>
                        </Menu.Item>
                    </Menu.Menu>

                    <Menu.Menu style={{ width: '55%', marginLeft: 'auto', marginRight: 'auto' }}>
                        {/* <Search disabled className="search-bar" fluid style={{ width: "100%", marginTop: ".8rem" }} size="large" placeholder={t('search')} /> */}
                    </Menu.Menu>

                    <Menu.Menu position="right">
                        <Menu.Item>
                            {/* <LanguageSelector /> */}
                        </Menu.Item>
                        <Menu.Item>
                            {!user?.profile_picture ? (
                                <Label
                                    circular
                                    className="circular-label"
                                    style={{
                                        marginRight: '.5rem',
                                        backgroundColor: 'var(--dark)',
                                        color: 'white',
                                    }}
                                >
                                    {user.username ? user?.username.charAt(0).toUpperCase() : ''}
                                </Label>
                            ) : (
                                <Image src={user?.profile_picture} style={{ marginRight: '.5rem' }} avatar />
                            )}
                            <Dropdown text={user?.username}>
                                <Dropdown.Menu direction="left" style={{ marginTop: '1rem', borderRadius: '0' }}>
                                    <Dropdown.Item
                                        as="button"
                                        icon={
                                            <Icon
                                                name="log-out-outline"
                                                style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}
                                            />
                                        }
                                        text={
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {' '}
                                                {t('sign_out')}{' '}
                                            </span>
                                        }
                                        onClick={logout}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        </Fragment>
    )
}

export default MainNavigation
