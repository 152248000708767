import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// store
import { routes } from '@routes';
import { authService } from '@services/ServiceAuth';
// components
import Loading from '../general/Loading';

const PublicRoute = ({component: Component, restricted}) => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPath] = useState(window.location.pathname);

    useEffect( () => {
        async function checkUser() {
            const result = await authService.validateToken();
            setIsLoading(false);

            if( result !== false && restricted ){
                history(routes.DASHBOARD);
            }
        };

        checkUser();
        // eslint-disable-next-line
    }, [history, currentPath, restricted]);

    if( isLoading ){
        return <Loading/>;
    } else {
        return (
            <Component/>
        );
    }
};

export default PublicRoute;