export const addUser = (user) => ({
    type: "ADD_USER",
    user,
});

export const logoutUser = () => ({
    type: "LOGOUT_USER",
});

export const addLanguage = (payload) => ({
    type: "ADD_LANGUAGE",
    payload,
});

export const addUserPermissions = (permissions) => ({
    type: "ADD_PERMISSIONS",
    permissions,
});

export const addChoices = (choices) => ({
    type: "ADD_CHOICES",
    choices,
});

export const addModulePreferences = (payload) => ({
    type: 'ADD_MODULE_PREFERENCES',
    payload,
})